import Global from '~icons/icons/GlobalMenuLogo.svg'
import GiorgioArmani from '~icons/icons/GiorgioArmaniMenuLogo.svg'
import GaPoldo from '~icons/icons/GaPoldoMenuLogo.svg'
import GaNeve from '~icons/icons/GaNeveMenuLogo.svg'
import EmporioArmani from '~icons/icons/EmporioArmaniMenuLogo.svg'
import Ea7 from '~icons/icons/Ea7MenuLogo.svg'
import ArmaniExchange from '~icons/icons/ArmaniExchangeMenuLogo.svg'
import ArmaniCasa from '~icons/icons/ArmaniCasaMenuLogo.svg'
import ArmaniFiori from '~icons/icons/ArmaniFioriMenuLogo.svg'
import ArmaniDolci from '~icons/icons/ArmaniDolciMenuLogo.svg'
import ArmaniBeauty from '~icons/icons/ArmaniBeautyMenuLogo.svg'
import ArmaniRestaurant from '~icons/icons/ArmaniRestaurantMenuLogo.svg'
import ArmaniHotelsResorts from '~icons/icons/ArmaniHotelsResortsMenuLogo.svg'
import ArmaniSilos from '~icons/icons/ArmaniSilosMenuLogo.svg'
import ArmaniClubs from '~icons/icons/ArmaniClubsMenuLogo.svg'
import EchiDalMondo from '~icons/icons/EchiDalMondoMenuLogo.svg'

export type MenuLogoType = keyof typeof menuLogoMap

export const menuLogoMap = {
  global: Global,
  'giorgio-armani': GiorgioArmani,
  'ga-poldo': GaPoldo,
  'ga-neve': GaNeve,
  'emporio-armani': EmporioArmani,
  ea7: Ea7,
  'armani-exchange': ArmaniExchange,
  'armani-casa': ArmaniCasa,
  'armani-fiori': ArmaniFiori,
  'armani-dolci': ArmaniDolci,
  'armani-beauty': ArmaniBeauty,
  'armani-restaurant': ArmaniRestaurant,
  'armani-hotels-resorts': ArmaniHotelsResorts,
  'armani-silos': ArmaniSilos,
  'armani-clubs': ArmaniClubs,
  'echi-dal-mondo': EchiDalMondo,
}
